import pl from '../translations/pl.json';
import en from '../translations/en.json';
import de from '../translations/de.json';
import '@wikimedia/jquery.i18n/src/jquery.i18n';
import '@wikimedia/jquery.i18n/src/jquery.i18n.messagestore';

jQuery(function ($) {
    var update_texts = function() { $('body').i18n() };
  $.i18n().load({
    pl: pl,
    en: en,
    de: de
  }).done( function() { console.log('Translations loaded!') } );
    update_texts();

  $('.lang-switch').on('click', function(e) {
    e.preventDefault();
    $.i18n().locale = $(this).data('locale');
    update_texts();
    console.log('Translations loaded!')
  });
});