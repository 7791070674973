if($(window).width() > 1140) {
  $(document).on("scroll", function() {
    var pageTop = $(document).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var tags = $(".fade-right");

    for (var i = 0; i < tags.length; i++) {
      var tag = tags[i];
  
      if ($(tag).position().top + 180 < pageBottom) {
        $(tag).addClass("visible");
      }
    }
  });
} else {
  var mainTag = $(".info-container");
  $(mainTag).removeClass("fade-right");
  $(mainTag).addClass("fade-left");
  $(document).on("scroll", function() {
    var pageTop = $(document).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var tags = $(".fade-left");

    for (var i = 0; i < tags.length; i++) {
      var tag = tags[i];
  
      if ($(tag).position().top + 180 < pageBottom) {
        $(tag).addClass("visible");
      }
    }
  });
}
