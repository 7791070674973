// Load Bootstrap JS
import bootstrap from "bootstrap";
import { Toast } from "bootstrap";

// Load Styles
import "../scss/main.scss";
import "../scss/navigation/navigation.scss";
import "../scss/banner/banner.scss";
import "../scss/about/about.scss";
import "../scss/info/info.scss";
import "../scss/contact/contact.scss";

// Load images
import "../assets/banner/banner.JPG";
import "../assets/navbar/LOGO.png";
import "../assets/info/PP_20.10.21_209b-1.jpg";
import "../assets/info/PP_20.10.21_307.jpg";
import "../assets/contact/05.11.21_024.jpg";

//Load js
import "./fade-left";
import "./fade-right";
import "./scrollSpy";
import "./translate";

//Load code
window.onload = () => {
  let myToast = document.querySelector(".toast");
  let bsToast = new Toast(myToast);
  bsToast.show();
};
